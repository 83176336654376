/* eslint-disable no-redeclare */
import { convertDateTO } from "utils/utility";
import * as XLSX from "xlsx";

var wscols = [
  { width: 28.75 },
  { width: 10.75 },
  { width: 10.75 },
  { width: 10.75 },
  { width: 10.63 },
  { width: 6.13 },
  { width: 34.50 },
  { width: 17.38 },
  { width: 10.75 },
  { width: 50 },
];

export const arrayToExcel = (function () {
  function exportExcel(tableText, filename) {
    const wb = XLSX.utils.book_new();
    /* create element from the source */
    var elt = document.createElement("div");
    elt.innerHTML = tableText;
    document.body.appendChild(elt);
    /* generate worksheet */
    var ws = XLSX.utils.table_to_sheet(elt.getElementsByTagName("TABLE")[0], {
      raw: true,
      type: "string",
    });
    ws["!cols"] = wscols;
    /* remove element */
    document.body.removeChild(elt);
    XLSX.utils.book_append_sheet(wb, ws, "PatientReferralReport");
    XLSX.writeFile(wb, filename);
  }

  const createprocedureCodes = (item) => {
    return item && item.length > 0
      ? item.map((itnm) => `${itnm.typeCode}-${itnm.typeDescription}`).join(",")
      : "";
  };

  const reportsTable = (tableHeader, apiArray) => {
    // Start table and create header row dynamically
    let table = "<table><thead><tr>";
    for (const header of tableHeader) {
      table += `<th><b>${header}</b></th>`;
    }
    table += "</tr></thead><tbody>";

    // Dynamically generate rows based on apiArray and tableHeader keys
    for (const item of apiArray) {
      table += "<tr>";
      for (const key of tableHeader) {
        const value = item[key];
        // Apply specific transformations if needed
        const cellValue =
          key === "dob" || key === "accidentDate" || key === "dateOfService"
            ? convertDateTO(value)
            : key === "procedureCodes"
              ? createprocedureCodes(value)
              : value ?? ""; // Use a fallback for null/undefined
        table += `<td>${cellValue}</td>`;
      }
      table += "</tr>";
    }

    table += "</tbody></table>";
    return table;
  };


  //STEP 1: Convert Array to HTML Table.
  return {
    convertArrayToTable: async (tableHeader, apiArray, fileName) => {
      const table = reportsTable(tableHeader, apiArray, fileName);
      exportExcel(table, fileName);
    },
  };
})();
