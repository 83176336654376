import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import Tree from "rc-tree";
import CreatableSelect from "react-select/creatable";
import ContentEditable from "react-contenteditable";
import Select from "react-select";
import Dropzone from "utils/dropzone";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { TbFile } from "react-icons/tb";
import { FcFolder, FcOpenedFolder } from "react-icons/fc";
import { IoCloseSharp } from "react-icons/io5";
import {
  GetContactList,
  GetEmailBody,
  getFile,
  SendFax,
  UploadFile,
  SendEmail,
  getFilesAsync, GetDocumentManagerNodeList, getDocument
} from "services/http/PatientList";
import * as storage from "../../services/storage";
import * as constant from "../../utils/constant";
import {
  FileToBase64,
  GenerateMergedPdf,
  imgToPdfBuffer,
} from "../../utils/utility";
import { saveAs } from "file-saver";
import toast from "react-hot-toast";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "rc-tree/assets/index.css";
import PdfRenderer from "utils/pdfRender";
import { useLoader } from "services/loaderService";

export const DocumentManager = ({
  isModalOpen,
  setIsModalOpen,
  currentTableRow,
  userUid,
  caseNo,
  companyId,
  caseId

}) => {
  const { showLoader, hideLoader } = useLoader();
  const FileModalRef = useRef();
  const [fileObject, setFileObject] = useState([]);
  const [showFileModal, setShowFileModalModal] = useState(false);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const EmailModalRef = useRef();
  const [isFaxModalOpen, setIsFaxModalOpen] = useState(false);
  const FaxModalRef = useRef();
  const treeRef = useRef(null);
  const [treeData, setTreeData] = useState(null);
  const [fileData, setfileData] = useState(null);
  const [showNodesWithOnlyDocument, setShowNodesWithOnlyDocument] =
    useState(true);
  const [includeCCBCC, setIncludeCCBCC] = useState(false);
  const DocumentManagerCloseRef = useRef(null);
  const [contactList, SetContactList] = useState(null);
  const [providerList, setProviderList] = useState(null);
  const [anchorPointTooltip, setAnchorPointTooltip] = useState({
    x: 0,
    y: 0,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const [activeNode, setActiveNode] = useState(null);
  const [emailFormData, setEmailFormData] = useState({
    to: "",
    subject: "",
    body: "",
    attachments: null,
  });
  const [faxFormData, setFaxFormData] = useState({
    to: "",
    from: "",
    message: "",
    attachments: null,
  });
  const [fileType, setFileType] = useState(null);
  const [treeDataComplete, setTreeDataComplete] = useState(null);
  const [checkedTreeNodes, setCheckedTreeNodes] = useState(null);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const getDocumentManagerData = async () => {
    try {
      setTreeData(null);
      setfileData(null);
      setFileType(null);

      const payload = {
        userUId: userUid,
        caseNo: caseNo,
        companyId: companyId,
      };

      showLoader();

      const data = await GetDocumentManagerNodeList(payload);

      if (![400, 401, 404].includes(data)) {
        setTreeDataComplete(data);

        let temp = await FormTreeview(data);

        // Apply filtering logic if needed
        if (showNodesWithOnlyDocument) {
          temp = customFilter(
            temp,
            ({ children }) => !children?.isFolder || children.length > 0
          );
          // eslint-disable-next-line
          temp = temp.filter(function f(o) {
            if (!o?.isFolder) return true;
            if (o.children) {
              return (o.children = o?.children?.filter(f)).length > 0;
            }
          });
        }

        setTreeData(temp);

        // Calculate expanded keys for all parent nodes
        const getAllKeys = (nodes) => {
          const keys = [];
          const traverse = (nodes) => {
            nodes?.forEach((node) => {
              if (node?.children && node?.children.length > 0) {
                keys.push(node?.key); // Add only keys with children
                traverse(node?.children); // Recursively process children
              }
            });
          };
          traverse(nodes);
          return keys;
        };

        const expandedKeys = getAllKeys(temp);
        setExpandedKeys(expandedKeys); // Update expanded keys state

        if (!isModalOpen) setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
        toast.error("Some error occurred, Please try again later.", {
          duration: 2500,
        });
      }
    } catch (error) {
      console.error("Error fetching document manager data:", error);
      setIsModalOpen(false);
      toast.error("Unexpected error occurred.", {
        duration: 2500,
      });
    } finally {
      hideLoader();
    }
  };



  const setTreeDataFiltered = async () => {
    let temp = await FormTreeview(treeDataComplete);
    temp = customFilter(temp, ({ children }) => children?.length > 0);
    // eslint-disable-next-line
    temp = temp?.filter(function f(o) {
      if (!o?.isFolder) return true;
      if (o?.children) {
        return (o.children = o?.children?.filter(f)).length;
      }
    });
    setTreeData(temp);
  };

  const setTreeDataUnfiltered = async () => {
    setTreeData(await FormTreeview(treeDataComplete));
  };

  useEffect(() => {
    if (showNodesWithOnlyDocument) {
      setTreeDataFiltered();
    } else {
      setTreeDataUnfiltered();
    }
    //eslint-disable-next-line
  }, [showNodesWithOnlyDocument]);

  useEffect(() => {
    // if (isModalOpen)
    getDocumentManagerData();
    //eslint-disable-next-line
  }, []);

  const getFileData = async (nodeId) => {
    setfileData(null);
    setFileType(null);
    let nodeData = treeDataComplete.filter((item) => item?.nodeID === nodeId[0]);
    nodeData = nodeData[0];
    if (
      nodeData &&
      !nodeData?.filePath?.includes("Folder.gif") &&
      nodeData?.filePath !== ""
    ) {
      showLoader()
      setFileType(nodeData?.nodeName?.includes(".pdf") ? "PDF" : "Image");
      let isError = false;
      let fileData = await getFile(
        `Document${nodeData?.filePath?.split("DocumentManager").pop(0)}`
      ).finally(() => {
        hideLoader()

      });
      fileData =
        fileData === 404 || fileData === 400
          ? (isError = true)
          : await FileToBase64(
            new Blob([fileData], {
              type: "application/pdf",
            })
          );
      if (!isError) {
        fileData =
          fileType === "PDF"
            ? `data:application/pdf;base64,${fileData?.substr(
              fileData?.indexOf(",") + 1
            )}`
            : `data:application/pdf;base64,${fileData?.substr(
              fileData?.indexOf(",") + 1
            )}`;
        setfileData(fileData);
        setFileType(nodeData?.nodeName?.includes(".pdf") ? "PDF" : "Image");
      } else {
        toast.error("File does not exists on server.", { duration: 2500 });
      }
      hideLoader()
    }
  };

  const switcherIcon = (obj) => {
    if (obj?.isLeaf) {
      return "";
    }
    return obj?.expanded ? (
      <MdKeyboardArrowDown size={20} style={{ backgroundColor: "white" }} />
    ) : (
      <MdKeyboardArrowRight size={20} style={{ backgroundColor: "white" }} />
    );
  };

  const handleContext = async ({ event, node }) => {
    setEmailFormData({
      to: "",
      subject: "",
      body: "",
      attachments: null,
      attachtedfileNames: null,
    });
    setFaxFormData({
      to: "",
      from: "",
      message: "",
      attachments: null,
      attachtedfileNames: null,
    });
    setActiveNode(null);
    setAnchorPointTooltip(null);
    setActiveNode(node);
    setEmailFormData((prev) => ({
      ...prev,
      attachments: [node?.url],
      attachtedfileNames: [node?.title],
    }));
    setFaxFormData((prev) => ({
      ...prev,
      attachments: [node?.url],
      attachtedfileNames: [node?.title],
    }));
    console.log(node.url);
    setAnchorPointTooltip({ x: event.pageX, y: event.pageY });
    setShowTooltip(true);
  };

  const handleContextHide = () => {
    setShowTooltip(false);
  };

  const handleIcon = (node) => {
    return node?.isFolder ? (
      node?.expanded ? (
        <FcOpenedFolder size={18} />
      ) : (
        <FcFolder size={18} />
      )
    ) : (
      <TbFile size={18} style={{ color: "var(--primary-bg-color-75)" }} />
    );
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    if (
      emailFormData.to === null ||
      emailFormData.to === undefined ||
      emailFormData.to === ""
    ) {
      toast.error("Please add atleast 1 recipient.");
      return false;
    }
    let lstrecipent = [];
    emailFormData?.to.split(",").forEach((item) =>
      lstrecipent.push({
        recipentname: null,
        recipentaddress: item,
        contactid: null,
        recipenttype: "To",
      })
    );
    emailFormData?.cc?.split(",").forEach((item) =>
      lstrecipent.push({
        recipentname: null,
        recipentaddress: item,
        contactid: null,
        recipenttype: "cc",
      })
    );
    emailFormData?.bcc?.split(",").forEach((item) =>
      lstrecipent.push({
        recipentname: null,
        recipentaddress: item,
        contactid: null,
        recipenttype: "bcc",
      })
    );
    SendEmail({
      Source: "Document manager",
      caseid: currentTableRow?.caseId,
      createdbyuserid: userUid,
      lstrecipent: lstrecipent,
      CompanyId: currentTableRow?.companyId,
      MessageBody: emailFormData.body,
      FilePath: [...emailFormData.attachments],
      MessageSubject: emailFormData.subject,
      Direction: "Outbound",
      CommunicationModeId: 2,
    }).then((res) => {
      if (res === "success") {
        toast.success(
          "Email sent successfully, Email will be delivered to recipient(s) shortly."
        );
        setIsEmailModalOpen(false);
      } else if (res === 400) {
        toast.error("File does not exists on server.");
        setIsEmailModalOpen(false);
      }
    });
  };

  const handleFaxSubmit = (event) => {
    showLoader()
    event.preventDefault();
    if (
      faxFormData.to === null ||
      faxFormData.to === undefined ||
      faxFormData.to === ""
    ) {
      toast.error("Please add atleast 1 recipient.");
      return false;
    }
    if (!constant.FaxRegex.test(faxFormData.to)) {
      toast.error("Please select valid fax.");
      return false;
    }
    let lstrecipent = [];
    faxFormData?.to.split(",").forEach((item) =>
      lstrecipent.push({
        recipentName: null,
        recipentAddress: item,
        contactId: null,
      })
    );
    let providerDetails = providerList.filter(
      (item) => item.officeId === faxFormData?.from
    );
    providerDetails = providerDetails[0];
    SendFax({
      Source: "Document manager",
      caseid: currentTableRow?.caseId,
      createdbyuserid: userUid,
      lstrecipent: lstrecipent,
      CompanyId: currentTableRow?.companyId,
      MessageBody: faxFormData.message,
      FilePath: [...faxFormData.attachments],
      officeId: providerDetails?.officeId,
      officeName: providerDetails?.officeName,
      Direction: "Outbound",
      CommunicationModeId: 4,
    })
      .then((res) => {
        hideLoader()
        if (res === "success") {
          toast.success(
            "Fax sent successfully, Fax will be delivered to recipient(s) shortly."
          );
          setIsFaxModalOpen(false);
        } else if (res === 400) {
          hideLoader()
          toast.error("File does not exists on server.");
          setIsFaxModalOpen(false);
        }
      })
      .finally(() => {
        hideLoader()
      });
  };

  const motion = {
    motionName: "node-motion",
    motionAppear: false,
    onAppearStart: () => ({ height: 0 }),
    onAppearActive: (node) => ({
      height: node.scrollHeight - 25,
    }),
    onLeaveStart: (node) => ({
      height: node.offsetHeight,
    }),
    onLeaveActive: () => ({ height: 0 }),
  };

  const handleFileUpload = async (e) => {
    showLoader()
    const tokenObject = storage.getLocalStorage(constant.STORAGE_TOKEN_KEY);
    e.preventDefault();
    let nodeData = treeDataComplete.filter(
      (item) => item?.nodeID === activeNode?.key
    );
    nodeData = nodeData[0];
    const _data = {
      NodeType: nodeData?.nodeType,
      UserID: userUid,
      CompanyID: currentTableRow?.companyId,
      Tocket: tokenObject?.token,
      NodeName: nodeData?.nodeName,
      CaseID: caseId,
      NodeId: nodeData?.nodeID,
      FileName: "",
      // UserName: auth?.profile?.name,
    };
    let postBody = new FormData();
    Object.entries(_data)?.map(([key, value]) => postBody?.append(key, value));
    Object.keys(fileObject).forEach((key) => {
      const file = fileObject[key];
      postBody.append(
        "AttachmentList",
        new Blob([file], { type: file.type }),
        file.name
      );
    });
    let response = await UploadFile(postBody);
    if (response > 0) {
      await getDocumentManagerData();
      setShowFileModalModal(false);
      setFileObject(null);
      hideLoader()
      toast.success("File(s) uploaded successfully.");
    } else {
      setShowFileModalModal(false);
      setFileObject(null);
      hideLoader()
      toast.error("Error uploading files. Please try again later.");
    }
  };

  const handleMultipleEmail = async () => {
    setEmailFormData({
      to: "",
      subject: "",
      body: "",
      attachments: null,
      attachtedfileNames: null,
    });
    const temp = checkedTreeNodes?.map(
      (item) => treeDataComplete.filter((_item) => _item?.nodeID === item)[0]
    );
    const fileUrls = temp?.filter(
      (item) => item.filePath !== "" && item.filePath !== "Folder.gif"
    );
    const tempFilePaths = fileUrls?.map((item) => item.filePath);
    const tempFileNames = fileUrls?.map((item) => item.nodeName);
    setEmailFormData((prev) => ({
      ...prev,
      attachments: tempFilePaths,
      attachtedfileNames: tempFileNames,
    }));
    if (fileUrls && fileUrls[0] !== undefined) {
      showLoader()
      let emailBody = await GetEmailBody(currentTableRow?.patientName, userUid);
      setEmailFormData((prev) => ({ ...prev, body: emailBody }));
      SetContactList(await GetContactList(currentTableRow?.companyId));
      setIsEmailModalOpen(true);
      hideLoader()
    } else {
      toast.error("Please select atleast 1 file.");
    }
  };

  const handleMultipleFax = async () => {
    setFaxFormData({
      to: "",
      from: "",
      message: "",
      attachments: null,
      attachtedfileNames: null,
    });
    const temp = checkedTreeNodes?.map(
      (item) => treeDataComplete.filter((_item) => _item?.nodeID === item)[0]
    );
    const fileUrls = temp?.filter(
      (item) => item.filePath !== "" && item.filePath !== "Folder.gif"
    );
    const tempFilePaths = fileUrls?.map((item) => item.filePath);
    const tempFileNames = fileUrls?.map((item) => item.nodeName);
    setFaxFormData((prev) => ({
      ...prev,
      attachments: tempFilePaths,
      attachtedfileNames: tempFileNames,
    }));
    if (fileUrls && fileUrls[0] !== undefined) {
      showLoader()
      SetContactList(await GetContactList(currentTableRow?.companyId));
      // GetProviderList(`?UserUid=${userUid}`).then((res) => {
      //   setProviderList(res);
      // });
      setIsFaxModalOpen(true);
      hideLoader()
    } else {
      toast.error("Please select atleast 1 file.");
    }
  };

  const getFilesData = async () => {
    let fileUrls = checkedTreeNodes
      ?.map(
        (item) => treeDataComplete?.find((_item) => _item?.nodeID === item) // Use find() for better performance
      )
      .filter((item) => item && item?.filePath !== "" && item.filePath !== "Folder.gif") // Filter valid files
      .map(({ filePath }) => `Document${filePath.split("DocumentManager").pop(0)}`);

    if (!fileUrls || fileUrls.length === 0) {
      toast.error("Please select at least 1 file.");
      return null; // Return null to indicate no valid files
    } else {
      let promises = await getFilesAsync(fileUrls);
      let data = await Promise.all(promises); // Resolve all promises

      // Call handleMultipleDownload after data is fetched
      await handleMultipleDownload(data); // Pass the fetched data to handleMultipleDownload
    }
  };

  const handleMultipleDownload = async (data) => {
    showLoader();

    // Early exit if data is invalid
    if (!data || data?.includes(null) || data?.length === 0) {
      hideLoader();
      toast.error("Some error occurred, file(s) may not exist on server.");
      return;
    }

    // Process the data only if it's valid
    let fileNames = checkedTreeNodes
      ?.map((item) => treeDataComplete?.find((_item) => _item?.nodeID === item)) // Use find() for better performance
      .filter((item) => item && item?.filePath !== "" && item.filePath !== "Folder.gif") // Ensure item exists and filePath is valid
      .map(({ nodeName }) => nodeName);

    // Map data to files
    let files = fileNames?.map((fileName, idx) =>
      new File([data[idx]], fileName, { type: data[idx]?.type, lastModified: Date.now() })
    );

    if (files && files.length > 0) {
      const zip = require("jszip")();
      files.forEach((file) => zip.file(file.name, file));

      let _zip = await zip.generateAsync({ type: "blob" });
      saveAs(_zip, `Merged_${caseNo}_${currentTableRow?.patientName}`);
      toast.success("Your zip file will be downloaded shortly.");
    } else {
      toast.error("No valid files to download.");
    }

    hideLoader(); // Hide loader after processing
  };



  const handleMultipleMerge = async () => {
    showLoader()
    let data = await getFilesData();
    if (!data.includes(null)) {
      data = data.map(async (item) =>
        item.type === "application/pdf"
          ? await item.arrayBuffer()
          : imgToPdfBuffer(item)
      );
      data = await Promise.all(data).then((res) => res);
      await GenerateMergedPdf(
        data,
        `Merged_${caseNo}_${currentTableRow?.patientName}`,
        "download"
      );
      toast.success("Your merged file will be downloaded shortly.");
    } else {
      toast.error("Some error occured, file(s) may not exist on server.");
    }
    hideLoader()
  };

  return (
    <>
      <Transition.Root show={showFileModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => { }}
          initialFocus={FileModalRef}
          static
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed flex justify-center inset-0 z-10">
            <div className="relative w-1/4 m-3 p-4 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-md bg-white text-left shadow-xl transition-all sm:my-8">
                  <div
                    style={{
                      maxHeight: 350,
                      overflow: "auto",
                    }}
                    className="bg-white rounded-md pt-5 p-0.5 sm:pt-2"
                  >
                    <div className=" sm:items-start">
                      <div
                        className="text-center sm:text-left"
                        ref={FileModalRef}
                      >
                        <Dialog.Title
                          as="h4"
                          className="text-base pl-2 pr-2 font-medium text-gray-900"
                        >
                          {currentTableRow?.patientName} [
                          {caseNo}] - {activeNode?.title}
                          <IoCloseSharp
                            className="float-right cursor-pointer"
                            size={20}
                            color="red"
                            onClick={() => {
                              setShowFileModalModal(false);
                            }}
                          />
                        </Dialog.Title>
                        <div className="border-b border-gray-300 mb-2"></div>
                        <form onSubmit={handleFileUpload}>
                          <div style={{ gridTemplateColumns: "30% 69%" }}>
                            <Dropzone
                              files={fileObject}
                              setFiles={setFileObject}
                            />
                          </div>
                          <div className="flex justify-center items-center">
                            <input
                              type="submit"
                              value={"Upload"}
                              className="p-2 my-2 rounded-md bg-primary-color-75 hover:bg-primary-color border-0 text-white font-bold"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={isFaxModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => { }}
          initialFocus={FaxModalRef}
          static
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed flex justify-center inset-0 z-10">
            <div className="relative w-2/4 m-3 p-4 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-md bg-white text-left shadow-xl transition-all sm:my-8">
                  <div
                    style={{ minHeight: 380, maxHeight: 380, overflow: "auto" }}
                    className="bg-white rounded-md pt-5 p-0.5 sm:pt-2"
                  >
                    <div className=" sm:items-start">
                      <div
                        className="text-center sm:text-left"
                        ref={FaxModalRef}
                      >
                        <Dialog.Title
                          as="h4"
                          className="text-base pl-2 pr-2 font-medium text-gray-900"
                        >
                          {currentTableRow?.patientName} [
                          {caseNo}]
                          <IoCloseSharp
                            className="float-right cursor-pointer"
                            size={20}
                            color="red"
                            onClick={() => {
                              setIsFaxModalOpen(false);
                            }}
                          />
                        </Dialog.Title>
                        <div className="border-b border-gray-300 mb-2"></div>
                        <form
                          onSubmit={handleFaxSubmit}
                          style={{ marginLeft: 15 }}
                        >
                          <div
                            style={{ gridTemplateColumns: "80px 100%" }}
                            className="grid grid-cols-2 gap-1"
                          >
                            <p style={{ marginTop: 7 }}>From:</p>
                            <div style={{ width: "85%" }}>
                              <Select
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "var(--primary-bg-color-75)",
                                    primary50: "var(--primary-bg-color-50)",
                                    primary25: "var(--primary-bg-color-25)",
                                    primary: "var(--primary-bg-color)",
                                  },
                                })}
                                className="form-select cutomClass form-react-select select customDorpdown"
                                options={providerList?.map((item) => ({
                                  value: item.officeId,
                                  label: `${item.officeName} (${item.officeAddress})`,
                                }))}
                                onChange={(e) =>
                                  setFaxFormData((prev) => ({
                                    ...prev,
                                    from: e.value,
                                  }))
                                }
                              />
                            </div>
                            <p style={{ marginTop: 7 }}>To:</p>
                            <div style={{ width: "85%" }}>
                              <CreatableSelect
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "var(--primary-bg-color-75)",
                                    primary50: "var(--primary-bg-color-50)",
                                    primary25: "var(--primary-bg-color-25)",
                                    primary: "var(--primary-bg-color)",
                                  },
                                })}
                                className=" form-select cutomClass form-react-select select customDorpdown"
                                options={contactList?.map((item) => ({
                                  value: item.sZ_Fax,
                                  label: `${item.contactName} (${item.sZ_Fax})`,
                                }))}
                                onChange={(e) =>
                                  setFaxFormData((prev) => ({
                                    ...prev,
                                    to: e.value,
                                  }))
                                }
                              />
                            </div>
                            <p style={{ marginTop: 7 }}>Message:</p>
                            <div style={{ width: "85%" }}>
                              <textarea
                                type="text"
                                title="Subject"
                                style={{ minHeight: 140, paddingLeft: 10 }}
                                name="Subject"
                                className="form-control searchInput"
                                placeholder="Message"
                                onChange={(e) =>
                                  setFaxFormData((emailFormData) => ({
                                    ...emailFormData,
                                    message: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <p style={{ marginTop: 7 }}>Attachments:</p>
                            <div style={{ width: "85%" }}>
                              <p
                                type="text"
                                style={{
                                  minHeight: 40,
                                  paddingLeft: 10,
                                  paddingTop: 5,
                                  display: "flex",
                                }}
                                name="Attachments"
                                className="form-control searchInput"
                                placeholder="attachments"
                                value={activeNode?.url}
                                disabled
                                onChange={(e) =>
                                  setFaxFormData((emailFormData) => ({
                                    ...emailFormData,
                                    attachments: e.target.value,
                                  }))
                                }
                              >
                                <span className="flex flex-col">
                                  {faxFormData?.attachtedfileNames?.map(
                                    (item, idx) => (
                                      <span
                                        className="flex"
                                        key={item + idx}
                                      >
                                        <TbFile
                                          style={{ marginTop: 3 }}
                                        ></TbFile>
                                        {item}
                                      </span>
                                    )
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-center items-center">
                            <input
                              type="submit"
                              value={"Send Fax"}
                              className="p-2 m-2 rounded-md bg-primary-color-75 hover:bg-primary-color border-0 text-white font-bold"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={isEmailModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => { }}
          initialFocus={EmailModalRef}
          static
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed flex justify-center inset-0 z-10">
            <div className="relative w-1/2 m-3 p-4 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-md bg-white text-left shadow-xl transition-all sm:my-8">
                  <div
                    style={{ minHeight: 610, maxHeight: 610, overflow: "auto" }}
                    className="bg-white rounded-md pt-5 p-0.5 sm:pt-2"
                  >
                    <div className=" sm:items-start">
                      <div
                        className="text-center sm:text-left"
                        ref={EmailModalRef}
                      >
                        <Dialog.Title
                          as="h4"
                          className="text-base pl-2 pr-2 font-medium text-gray-900"
                        >
                          {currentTableRow?.patientName} [
                          {caseNo}]
                          <label className="ml-2 text-xs">
                            Include CC & BCC
                          </label>
                          <Switch
                            checked={includeCCBCC}
                            onChange={setIncludeCCBCC}
                            className={`${includeCCBCC
                              ? "bg-primary-color"
                              : "bg-gray-200"
                              } relative ml-1 inline-flex h-4 w-6 items-center rounded-full`}
                          >
                            <span className="sr-only">
                              Enable notifications
                            </span>
                            <span
                              className={`${includeCCBCC
                                ? "translate-x-3"
                                : "translate-x-1"
                                } inline-block h-2 w-2 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                          <IoCloseSharp
                            className="float-right cursor-pointer"
                            size={20}
                            color="red"
                            onClick={() => {
                              setIsEmailModalOpen(false);
                            }}
                          />
                        </Dialog.Title>
                        <div className="border-b border-gray-300 mb-2"></div>
                        <form
                          id="emailForm"
                          onSubmit={handleEmailSubmit}
                          style={{ marginLeft: 15 }}
                        >
                          <div
                            style={{ gridTemplateColumns: "80px 100%" }}
                            className="grid grid-cols-2 gap-1"
                          >
                            <p style={{ marginTop: 7 }}>To:</p>
                            <div style={{ width: "85%" }}>
                              <CreatableSelect
                                isClearable
                                isMulti
                                theme={(theme) => ({
                                  ...theme,
                                  borderRadius: 0,
                                  colors: {
                                    ...theme.colors,
                                    primary75: "var(--primary-bg-color-75)",
                                    primary50: "var(--primary-bg-color-50)",
                                    primary25: "var(--primary-bg-color-25)",
                                    primary: "var(--primary-bg-color)",
                                  },
                                })}
                                className=" form-select cutomClass form-react-select select customDorpdown"
                                options={contactList?.map((item) => ({
                                  value: item.sZ_Email,
                                  label: item.sZ_Email,
                                }))}
                                onChange={(e) =>
                                  setEmailFormData((prev) => ({
                                    ...prev,
                                    to: e.map((item) => item.value).join(","),
                                  }))
                                }
                              />
                            </div>
                            {includeCCBCC && (
                              <>
                                <p style={{ marginTop: 7 }}>CC:</p>
                                <div style={{ width: "85%" }}>
                                  <CreatableSelect
                                    isClearable
                                    isMulti
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary75: "var(--primary-bg-color-75)",
                                        primary50: "var(--primary-bg-color-50)",
                                        primary25: "var(--primary-bg-color-25)",
                                        primary: "var(--primary-bg-color)",
                                      },
                                    })}
                                    className=" form-select cutomClass form-react-select select customDorpdown"
                                    options={contactList?.map((item) => ({
                                      value: item.sZ_Email,
                                      label: item.sZ_Email,
                                    }))}
                                    onChange={(e) =>
                                      setEmailFormData((prev) => ({
                                        ...prev,
                                        cc: e
                                          .map((item) => item.value)
                                          .join(","),
                                      }))
                                    }
                                  />
                                </div>
                                <p style={{ marginTop: 7 }}>BCC:</p>
                                <div style={{ width: "85%" }}>
                                  <CreatableSelect
                                    isClearable
                                    isMulti
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 0,
                                      colors: {
                                        ...theme.colors,
                                        primary75: "var(--primary-bg-color-75)",
                                        primary50: "var(--primary-bg-color-50)",
                                        primary25: "var(--primary-bg-color-25)",
                                        primary: "var(--primary-bg-color)",
                                      },
                                    })}
                                    className=" form-select cutomClass form-react-select select customDorpdown"
                                    options={contactList?.map((item) => ({
                                      value: item.sZ_Email,
                                      label: item.sZ_Email,
                                    }))}
                                    onChange={(e) =>
                                      setEmailFormData((prev) => ({
                                        ...prev,
                                        bcc: e
                                          .map((item) => item.value)
                                          .join(","),
                                      }))
                                    }
                                  />
                                </div>
                              </>
                            )}
                            <p style={{ marginTop: 7 }}>Subject:</p>
                            <div style={{ width: "85%" }}>
                              <input
                                type="text"
                                title="Subject"
                                style={{ height: 40, paddingLeft: 10 }}
                                name="Subject"
                                className="form-control searchInput"
                                placeholder="Subject"
                                onChange={(e) =>
                                  setEmailFormData((emailFormData) => ({
                                    ...emailFormData,
                                    subject: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <p style={{ marginTop: 7 }}>Body:</p>
                            <div style={{ width: "85%" }}>
                              <ContentEditable
                                html={emailFormData.body}
                                disabled={false}
                                tagName="article"
                                style={{ paddingLeft: 10 }}
                                className="form-control searchInput"
                                onChange={(e) => {
                                  setEmailFormData((prev) => ({
                                    ...prev,
                                    body: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <p style={{ marginTop: 7 }}>Attachments:</p>
                            <div style={{ width: "85%" }}>
                              <p
                                type="text"
                                style={{
                                  minHeight: 40,
                                  paddingLeft: 10,
                                  paddingTop: 5,
                                  display: "flex",
                                }}
                                name="Attachments"
                                className="form-control searchInput"
                                placeholder="attachments"
                                value={activeNode?.url}
                                disabled
                                onChange={(e) =>
                                  setEmailFormData((emailFormData) => ({
                                    ...emailFormData,
                                    attachments: e.target.value,
                                  }))
                                }
                              >
                                <span className="flex flex-col">
                                  {emailFormData?.attachtedfileNames?.map(
                                    (item, idx) => (
                                      <span
                                        className="flex"
                                        key={item + idx}
                                      >
                                        <TbFile
                                          style={{ marginTop: 3 }}
                                        ></TbFile>
                                        {item}
                                      </span>
                                    )
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-center items-center">
                            <input
                              type="submit"
                              value={"Send Email"}
                              className="p-2 m-2 rounded-md bg-primary-color-75 hover:bg-primary-color border-0 text-white font-bold"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={showTooltip} as={Fragment}>
        <div
          className="bg-white text-xs z-20 px-1 absolute rounded-md border-2 border-gray-200 shadow-lg "
          style={{
            top: anchorPointTooltip?.y,
            left: anchorPointTooltip?.x,
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {activeNode && activeNode?.isFolder ? (
              <div className="grid grid-cols-1">
                <button
                  onClick={() => {
                    setShowFileModalModal(true);
                    handleContextHide();
                  }}
                  className="p-3"
                  disabled={activeNode?.canUpload}
                >
                  Upload
                </button>
              </div>
            ) : activeNode && !activeNode?.isFolder ? (
              <div className="grid grid-cols-2 divide-x-2">
                <button
                  onClick={async () => {
                    showLoader()
                    handleContextHide();
                    let emailBody = await GetEmailBody(
                      currentTableRow?.patientName,
                      userUid
                    );
                    setEmailFormData((prev) => ({ ...prev, body: emailBody }));
                    SetContactList(
                      await GetContactList(currentTableRow?.companyId)
                    );
                    hideLoader()
                    setIsEmailModalOpen(true);
                  }}
                  className="p-3 opacity-80 hover:opacity-100"
                >
                  Email
                </button>
                <button
                  onClick={async () => {
                    showLoader()
                    handleContextHide();
                    SetContactList(
                      await GetContactList(currentTableRow?.companyId)
                    );
                    setEmailFormData((prev) => ({
                      ...prev,
                    }));
                    // GetProviderList(`?UserUid=${userUid}`).then((res) => {
                    //   setProviderList(res);
                    // });
                    showLoader()
                    setIsFaxModalOpen(true);
                  }}
                  className="p-3 opacity-80 hover:opacity-100"
                >
                  Fax
                </button>
              </div>
            ) : (
              <></>
            )}
          </Transition.Child>
        </div>
      </Transition.Root>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => { }}
          initialFocus={DocumentManagerCloseRef}
          static
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed flex justify-center inset-0 z-10">
            <div className="relative w-5/6 m-3 p-4 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-md bg-white text-left shadow-xl transition-all sm:my-8">
                  <div
                    style={{ minHeight: 'calc(100vh - 100px)' }}
                    onClick={handleContextHide}
                    className="bg-white rounded-md pt-5 p-0.5 sm:pt-2"
                  >
                    <div className="sm:items-start">
                      <div
                        className="text-center sm:text-left"
                        ref={DocumentManagerCloseRef}
                      >
                        <Dialog.Title
                          as="h4"
                          className="text-base leading-6 pl-2 pr-2 font-sans  text-gray-700"
                        >
                          Patient: {currentTableRow?.patientName} [{caseNo}]
                          <label className="ml-2 text-xs">Show All Nodes</label>
                          <Switch
                            checked={showNodesWithOnlyDocument}
                            onChange={setShowNodesWithOnlyDocument}
                            className={`${!showNodesWithOnlyDocument
                              ? "bg-cadetblue"
                              : " bg-gray-200"
                              } relative ml-1 inline-flex h-4 w-6 items-center rounded-full`}
                          >
                            <span className="sr-only">Enable notifications</span>
                            <span
                              className={`${!showNodesWithOnlyDocument
                                ? "translate-x-3"
                                : "translate-x-1"
                                } inline-block h-2 w-2 transform rounded-full bg-white transition`}
                            />
                          </Switch>
                          <button
                            className="ml-2 bg-gray-200 m-0 rounded-md hover:bg-cadetblue hover:text-white"
                            onClick={getFilesData}
                          >
                            <label
                              className="m-0 px-1 text-xs cursor-pointer"
                            >
                              Download Selected
                            </label>
                          </button>
                          <IoCloseSharp
                            className="float-right cursor-pointer"
                            size={20}
                            color="red"
                            onClick={() => {
                              setIsModalOpen(false);
                              handleContextHide();
                            }}
                          />
                        </Dialog.Title>
                        <div className="border-b border-gray-300 mb-2"></div>
                        <div className="modalGrid">
                          <div
                            className="animation"
                            onScroll={() => handleContextHide()}
                            style={{
                              overflow: "auto",
                              maxHeight: 'calc(100vh - 100px)',
                            }}
                          >
                            <style
                              dangerouslySetInnerHTML={{ __html: STYLE }}
                            />
                            {treeData && (
                              <Tree
                                expandedKeys={expandedKeys}  // Pass expandedKeys to control expansion
                                onExpand={(keys) => setExpandedKeys(keys)}
                                ref={treeRef}
                                motion={motion}
                                treeData={treeData}
                                showIcon
                                checkable
                                onCheck={(e) => setCheckedTreeNodes(e)}
                                icon={handleIcon}
                                onRightClick={handleContext}
                                switcherIcon={switcherIcon}
                                onSelect={getFileData}
                              />
                            )}
                          </div>
                          {fileType === "PDF"
                            ? fileData && <PdfRenderer fileData={fileData} />
                            : fileType === "Image"
                              ? fileData && (
                                <div>
                                  <img
                                    src={fileData}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: 'calc(100vh - 100px)',
                                    }}
                                    alt="File"
                                  />
                                </div>
                              )
                              : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

    </>
  );
};

async function findResultforDocument(result, list) {
  let rows = [];
  var containResult = result?.filter(function (item) {
    return item.parentID === list.key;
  });
  if (containResult?.length > 0) {
    for (var j = 0; j < containResult.length; j++) {
      let child = {};
      if (
        !containResult[j].nodeName.includes(".pdf") &&
        !containResult[j].nodeName.includes(".png") &&
        !containResult[j].nodeName.includes(".jpeg") &&
        !containResult[j].nodeName.includes(".jpg") &&
        !containResult[j].nodeName.includes(".gif")
      ) {
        child.children = [];
        child.url = "";
        child.isFolder = true;
      } else {
        child.children = [];
        child.url = containResult[j].filePath;
        child.isFolder = false;
      }
      child.title = containResult[j].nodeName;
      child.key = containResult[j].nodeID;
      child.expanded = 1;
      child.canUpload = containResult[j].canUpload;
      child.canDelete = containResult[j].canDelete;
      child.checked = 0;
      rows.push(child);
    }
    if (rows.length > 0) {
      list.children = rows;
    }
    for (var i = 0; i < list.children.length; i++) {
      await findResultforDocument(result, list.children[i]);
    }
  }
  return [list];
}

export async function FormTreeview(result) {
  let list = {};
  let tempResult = result?.filter((item) => item.parentID === "")[0];
  list.key = tempResult?.nodeID;
  list.title = tempResult?.nodeName;
  list.url = "";
  list.parentID = tempResult?.parentID;
  list.checked = 0;
  list.expanded = 1;
  list.canUpload = tempResult?.canUpload;
  list.canDelete = tempResult?.canDelete;
  list.isFolder = true;
  let SubData = await findResultforDocument(result, list);
  return SubData;
}

export function customFilter(array, fn) {
  return array?.reduce((r, o) => {
    var children = customFilter(o.children || !o.isFolder || [], fn);
    if (fn(o) || children.length || !children.isFolder)
      r.push(
        Object.assign(
          {},
          o,
          children.length || (!children.isFolder && { children })
        )
      );
    return r;
  }, []);
}

export const STYLE = `
.node-motion {
  transition: all .3s;
  overflow: hidden;
}`;
