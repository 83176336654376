
import React, { useEffect } from "react";
import MultiSelectWithCheckbox from "components/controls/multiselectComponent";
import { showReportStatus } from "../Visit/data";
import { useState } from "react";
import DateRange from "shared/DateRange";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";

const VisitListFilters = (props) => {
  const { onSearchChange, caseType, caseNo } = props;
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedCaseType, setSelectedCaseType] = useState('');
  const [duration, setDuration] = useState('');
  const [debouncedSearchText, setDebouncedSearchText] = useState(searchText);
  const fromDate = startOfMonth(addMonths(new Date(), -3));
  const toDate = new Date();
  const defaultRange = [fromDate, toDate];
  const [previousStatus, setPreviousStatus] = useState('');
  const [previousCaseType, setPreviousCaseType] = useState('');

  const handleDropdownChange = async (option, event) => {
    const value = event.map((item) => item.value);
    switch (option) {
      case "selectedStatus":
        setSelectedStatus(value);
        break;
      case "selectedCaseType":
        setSelectedCaseType(value);
        break;
      default:
        break;
    }
    // onSearchChange({ [option]: value });
  };

  const onChangeSearchName = (event) => {
    const value = event.target.value;
    setSearchText(value);

    // Trigger immediate API call if the field is cleared
    if (value === "") {
      onSearchChange({ searchText: "" });
    }
  };

  // useEffect for implementing the debounce functionality
  useEffect(() => {
    if (searchText === "") {
      return; // Avoid setting a debounce if the field is empty
    }

    // Set a timeout to update the debounced value after 2.5 seconds
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText);
    }, 2500); // 2500ms = 2.5 seconds

    // Clear the timeout if the value changes within the delay period
    return () => {
      clearTimeout(handler);
    };
  }, [searchText]);

  // Trigger the actual search when debouncedSearchText changes
  useEffect(() => {
    if (debouncedSearchText !== "") {
      onSearchChange({ searchText: debouncedSearchText });
    }
  }, [debouncedSearchText]);


  const handleDate = (val) => {
    if (!val) {
      setDuration(null); // Clear the duration state
      onSearchChange({
        duration: 'all',
        fromDate: '',
        toDate: '',
      });
      return;
    }
    // Check if either start or end date is incomplete or invalid
    if (!isValidCompleteDate(val)) {
      setDuration(null); // Clear the duration state for invalid inputs
      return;
    }

    // Valid dates: Update the state and onSearchChange only when both dates are valid
    const [startDate, endDate] = val;

    // You can also add an additional check for date ranges, such as fromDate <= toDate
    if (new Date(startDate) > new Date(endDate)) {
      // Handle the case when the start date is later than the end date (optional)
      return;
    }

    // Valid dates: Update the state and onSearchChange
    const newSearchEventDate = {
      fromDate: val[0],
      toDate: val[1],
    };

    onSearchChange({
      fromDate: newSearchEventDate.fromDate,
      toDate: newSearchEventDate.toDate,
      duration: '',
    });

    setDuration(val);
  };

  const isValidCompleteDate = (dateArray) => {

    if (!Array.isArray(dateArray) || dateArray.length !== 2) return false;

    return dateArray.every((dateString) => {
      if (!dateString) return false;

      // Parse and check if it's a valid date
      const parsedDate = new Date(dateString);
      return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
    });
  };

  const handleDropdownBlur = (option) => {
    switch (option) {
      case "selectedStatus":
        if (selectedStatus !== previousStatus) {
          setPreviousStatus([...selectedStatus]);
          onSearchChange({ selectedStatus });
        }
        break;

      case "selectedCaseType":
        if (selectedCaseType !== previousCaseType) {
          setPreviousCaseType([...selectedCaseType]);
          onSearchChange({ selectedCaseType });
        }
        break;

      default:
        break;
    }

  };



  return (
    <React.Fragment>
      <div className="row flex flex-wrap items-center gap-2">
        <div className="form-group sm:w-auto">
          <label htmlFor="staticEmail2" className="block font-bold">
            Search
          </label>
          <input
            type="text"
            onChange={(e) => onChangeSearchName(e)}
            value={searchText || ""}
            name="searchText"
            id="recipient-name-reports"
            placeholder="Search By Keyword"
            className="w-full sm:w-auto border border-gray-300 rounded-sm p-2 text-sm h-9 focus:outline-none focus:ring-2 focus:ring-blue-500"
            style={{ fontSize: "12px", borderRadius: "6px" }}
          />
        </div>

        <div className="form-group sm:w-auto">
          <DateRange
            value={duration}
            label={<span className="font-bold">DOS</span>}
            onChangeDate={(val) => handleDate(val)}
            defaultRange={caseNo ? [] : defaultRange}
          />
        </div>

        <div className="form-group sm:w-1/6" style={{ zIndex: 2 }}
          tabIndex="0"
          onBlur={() => handleDropdownBlur("selectedStatus")}
        >
          <label>
            <b> Visit Status</b>
          </label>
          <MultiSelectWithCheckbox
            singleSelect={false}
            options={showReportStatus}
            onChange={handleDropdownChange.bind(this, "selectedStatus")}
            value={Array.isArray(selectedStatus) ? selectedStatus.map(value => {
              return showReportStatus.find(option => option.value === value);
            }) : []}
            valueLabel={"Visit Status"}
          />
        </div>

        <div className="form-group sm:w-1/6" style={{ zIndex: 2 }}
          tabIndex="0"
          onBlur={() => handleDropdownBlur("selectedCaseType")}
        >
          <label>
            <b>Case Type</b>
          </label>
          <MultiSelectWithCheckbox
            singleSelect={false}
            options={caseType}
            onChange={handleDropdownChange.bind(this, "selectedCaseType")}
            value={Array.isArray(selectedCaseType) ? selectedCaseType.map(value => {
              return caseType.find(option => option.value === value);
            }) : []}
            valueLabel={"case Type"}
          />
        </div>
      </div>

    </React.Fragment>
  );
};

export default VisitListFilters;
