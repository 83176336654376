import React, { useEffect, useState, useRef } from "react";
import Table from "shared/Datatable";
import VisitListFilters from "./filters";
import { Link } from "react-router-dom";
import PatientDetailsModal from "../Visit/patientDetailsModal";
import { DocumentManager } from "../Visit/DocumentManagerModal";
import { HiOutlineDocumentText } from "react-icons/hi";
import Pagination from "shared/Pagination/Pagination";
import { getVisitList } from "services/http/PatientList";
import * as moment from "moment";
import { useLoader } from "services/loaderService";
import toast from "react-hot-toast";
import { reportsTableHeaders } from "../Visit/data";
import { arrayToExcel } from "shared/exportToExcel/ArrayToExcel";
import { getLocalStorage } from "services/storage";
import { getCaseType } from "services/http/Dropdown";
import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import { useLocation } from "react-router-dom";


const VisitList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const [patientListData, setPatientListData] = useState([]);
  const [casTypeData, setCasTypeData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [selectedRow, setSelectedRow] = useState({});
  const location = useLocation();

  const { caseNo } = location.state || {};
  console.log('caseNo', caseNo)
  const [searchValues, setSearchValues] = useState({
    CaseNo: caseNo || '',
    searchText: '',
    selectedCaseType: '',
    selectedStatus: '',
    selectedAttorney: '',
    fromDate: null,
    toDate: null,
    duration: caseNo ? 'all' : '',
  });

  // Ref to track initial render
  const isFirstRender = useRef(true);
  const profile = getLocalStorage('_profile')

  const Cell = ({ v, bold, className }) => (
    <span
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontWeight: bold ? "700" : "",
      }}
      title={v}
      className= {className || ""}
    >
      {v}
    </span>
  );

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setSelectedRow({})
    setIsOpen(false)
  };

  const openDocumentModal = () => setIsOpenDocument(true);
  const closeDocumentModal = () => {
    setSelectedRow({})
    setIsOpenDocument(false)
  };

  const caseTypeList = async () => {
    showLoader()
    const payload = {
      "UserUid": profile?.userUid,
    }
    try {
      let result = await getCaseType(payload);
      if (result != null) {
        if (result?.success) {
          const options = result?.response?.receiveObj?.data?.data?.caseTypesRecords?.map(c => {
            return {
              value: c.caseType, label: c.caseType
            }
          })
          setCasTypeData(options);
          hideLoader();
        } else {
          hideLoader();
          toast.error(result?.response?.receiveObj?.data?.message || "Something went wrong!");
        }
      }
      else {
        hideLoader();
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader();
      toast.error(error?.response?.receiveObj?.response?.data || "Something went wrong!");
      console.log(error);
    }
  }

  const columns = [
    {
      name: "Case# ",
      selector: (row) => row.caseNo,
      sortable: true,
      width: "5%",
      center: true,
      cell: (row) => (
        <>
          <Link
            data-tag="allowRowEvents"
            onClick={() => {
              setSelectedRow(row)
              openModal();
            }}
            title={'Case Details'}
            style={{ color: "blue", textDecoration: "underline" }}
            to={""}
          >
            {row.caseNo}
          </Link>
          <HiOutlineDocumentText
            data-tag="allowRowEvents"
            onClick={() => {
              setSelectedRow(row)
              openDocumentModal();
            }}
            title="Document Manager"
            style={{
              color: "cadetblue",
              cursor: "pointer",
              marginLeft: 10
            }}
            size={16}
          />
        </>

      ),
    },
    {
      name: "Patient",
      selector: (row) => row?.patientName,
      sortable: true,
      width: "10%",
      cell: (row) => <Cell v={row.patientName} />
    },
    {
      name: "DOB",
      selector: (row) => row?.dob,
      sortable: true,
      right: true,
      width: "6%",
      cell: (row) => <Cell v={row?.dob ? moment(new Date(row?.dob)).format("MM/DD/YYYY") : ''} />

    },
    {
      name: "DOA",
      selector: (row) => row?.accidentDate,
      cell: (row) => <Cell v={row?.accidentDate ? moment(new Date(row?.accidentDate)).format("MM/DD/YYYY") : ''} />,
      width: "6%",
      sortable: true,
      right: true,
    },
    {
      name: "DOS",
      selector: (row) => row?.dateOfService,
      sortable: true,
      right: true,
      width: "6%",
      cell: (row) => <Cell v={row?.dateOfService ? moment(new Date(row?.dateOfService)).format("MM/DD/YYYY") : ''} />,
    },
    {
      name: "Visit Status",
      selector: (row) => row?.visitStatus,
      sortable: true,
      width: "6%",
      cell: (row) => <Cell v={row.visitStatus} />
    },
    {
      name: " Case Type",
      selector: (row) => row?.caseType,
      sortable: true,
      width: "6%",
      cell: (row) => <Cell v={row.caseType} />
    },
    {
      name: "Provider",
      selector: (row) => row?.providerOfficeName,
      cell: (row) => <Cell v={row.providerOfficeName} />,
      width: "10%",
      sortable: true,
    },
    {
      name: "Office",
      selector: (row) => row?.providerOfficeAddress,
      sortable: true,
      width: "10%",
      cell: (row) => <Cell v={row.providerOfficeAddress} />
    },
    {
      name: "Insurance",
      selector: (row) => row?.insuranceName,
      cell: (row) => <Cell v={row.insuranceName} />,
      width: "11%",
      sortable: true,

    },
    {
      name: "Claim#",
      selector: (row) => row?.claimNumber,
      sortable: true,
      width: "6%",
      cell: (row) => <Cell v={row.claimNumber} />
    },
    {
      name: "Policy#",
      sortable: true,
      width: "6%",
      selector: (row) => row?.policyNumber,
      cell: (row) => <Cell v={row.policyNumber} />
    },
    {
      name: "Procedures",
      selector: (row) => row.age, // Example selector; adjust as necessary
      sortable: true,
      center: true,
      cell: (row) => (
        <>
        <div
      style={{
        display: "block", // Override the default flex layout
      }}
    >
          {row?.procedureCodes &&
            row.procedureCodes.length > 0 &&
            row.procedureCodes.map((item, index) => (
              <React.Fragment key={index}>
                {/* Pass the formatted string to the Cell component */}
                <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width:"120px"
                }}
                title={`${item.typeCode}-${item.typeDescription}`}
                className= "badge bg-default badge-sm me-1 mt-1"
              >
                {`${item.typeCode}-${item.typeDescription}`}
              </span>
                <br/>
              </React.Fragment>
            ))}
            </div>
        </>
      ),
    },
  ];

  const getListData = async (pageIndex = 1, pageSize = 25, searchValues) => {
    console.log('searchValues', searchValues)
    showLoader()
    let startDate = searchValues?.fromDate;
    let endDate = searchValues?.toDate;

    const payload = {
      "userUid": profile.userUid,
      "pageIndex": pageIndex.toString(),
      "pageSize": pageSize.toString(),
      "duration": searchValues?.duration,
      "startDate": searchValues?.duration ? '' : (startDate === null || startDate === undefined)
        ? moment(startOfMonth(addMonths(new Date(), -3))).format('MM-DD-YYYY')
        : moment(startDate).format('MM-DD-YYYY'),
      "endDate": searchValues?.duration ? '' : (endDate === null || endDate === undefined)
        ? moment(new Date()).format('MM-DD-YYYY')
        : moment(endDate).format('MM-DD-YYYY'),
      "status": arrayToCommaSeparatedString(searchValues?.selectedStatus),
      "ProviderOffice": '',
      "caseType": arrayToCommaSeparatedString(searchValues?.selectedCaseType),
      'searchKeyword': searchValues?.searchText,
      'CaseNo': searchValues?.CaseNo
    }
    try {
      let result = await getVisitList(payload);
      if (result != null) {
        if (!result?.success) {
          hideLoader()
          toast.error("Something went wrong!");
        } else {
          if (result.success) {
            setPatientListData(result?.response?.receiveObj?.data?.data)
          };
          hideLoader()
        }
      }
      else {
        hideLoader()
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader()
      toast.error(error?.response?.receiveObj?.message || "Something went wrong!");
      console.log(error);

    }
  }

  const arrayToCommaSeparatedString = (array) => {
    return Array.isArray(array) ? array.join(',') : '';
  };

  // useEffect for initial page load and whenever searchValues, pageIndex, or pageSize change
  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render to avoid double call
      isFirstRender.current = false;
    } else {
      getListData(pageIndex, pageSize, searchValues);
    }
  }, [pageIndex, pageSize, searchValues]);// Dependencies include searchValues, pageIndex, and pageSize


  useEffect(() => {
    caseTypeList()
    getListData(pageIndex, pageSize, searchValues);
  }, []);//




  const handlePageIndexChange = (index) => {
    setPageIndex(index);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
  };


  const onSearchChange = (newSearchValues) => {
    setPageIndex(1); // Reset to first page when search values change
    setSearchValues((prevValues) => {
      const prevSelectedValues = {
        selectedStatus: prevValues.selectedStatus,
        selectedCaseType: prevValues.selectedCaseType,
        searchText: prevValues.searchText,
        fromDate: prevValues.fromDate,
        duration: prevValues.duration,
        CaseNo: prevValues.CaseNo
      };

      const updatedSearchValues = {
        ...prevSelectedValues,
        ...newSearchValues,
      };

      return updatedSearchValues;
    });
  };

  const handleGenerateExcel = async () => {
    showLoader()
    const payload = {
      "userUid": profile.userUid,
      "pageIndex": "1",
      "pageSize": patientListData?.totalCount?.toString(),
    }
    try {
      let result = await getVisitList(payload);
      if (result != null) {
        if (!result?.success) {
          toast.error("Something went wrong!");
          hideLoader()
        } else {
          if (result.success) {
            const data = result?.response?.receiveObj?.data?.data?.patientReferralReportRecords;

            // Check if data exists
            if (data) {

              const createprocedureCodes = (item) => {
                return item && item.length > 0
                  ? item.map((itnm) => `${itnm?.typeCode}-${itnm?.typeDescription}`)?.join(",")
                  : "";
              };
              const formattedData = data.map(record => ({
                "Patient": record.patientName,
                "DOB": record.dob
                  ? moment(record.dob).format("MM/DD/YYYY") // Format 'dob' to dd/mm/yyyy
                  : record.dob,
                "DOA": record.accidentDate
                  ? moment(record.accidentDate).format("MM/DD/YYYY") // Format 'dob' to dd/mm/yyyy
                  : record.accidentDate,
                "DOS": record.dateOfService
                  ? moment(record.dateOfService).format("MM/DD/YYYY") // Format 'dob' to dd/mm/yyyy
                  : record.dateOfService,
                "Visit Status": record.visitStatus,
                "Case Type": record.caseType,
                "Provider": record.providerOfficeName,
                "Office": record.providerOfficeAddress,
                "Insurance": record.insuranceName,
                "Claim#": record.claimNumber,
                "Policy#": record.policyNumber,
                "Procedures": createprocedureCodes(record.procedureCodes),
              }));
              arrayToExcel.convertArrayToTable(
                reportsTableHeaders,
                formattedData,
                "VisitReport.xlsx"
              );
            }
          }

          hideLoader()

        }
      }
      else {
        hideLoader()
        toast.error("Something went wrong!");
      }
    }
    catch (error) {
      hideLoader()
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <div class="flex flex-col my-4 bg-white shadow-md rounded-lg w-100 mx-4">
      <div className="row" style={{ zIndex: 1 }}>
        <div className="col-12 col-sm-12">
          <div className=" mx-auto mt-2  pb-2 border-b border-gray-200">
            <div className="grid grid-cols-12 flex-wrap">
            <div className="col-span-11 mx-4">
              <VisitListFilters onSearchChange={onSearchChange} caseType={casTypeData} caseNo={caseNo} />
              </div>
              {patientListData?.patientReferralReportRecords?.length > 0 && (
                <div className="mt-[27px] col-span-1 text-right mr-5">
                  <i
                    className="fas fa-file-excel text-cadetblue text-3xl cursor-pointer"
                    title="Excel"
                    onClick={() => handleGenerateExcel()}
                  ></i>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col min-h-[62vh] max-h-[62vh] my-4 mx-4">
            <Table
              columns={columns}
              data={patientListData?.patientReferralReportRecords}
              highlightOnHover
              // className="table-auto min-w-full min-h-[60vh] max-h-[60vh]"
            />

          </div>
          <div className="col-12 col-sm-12 mb-6 mx-4 ">
            {patientListData?.patientReferralReportRecords?.length > 0 && (
              <Pagination
                className="pagination-bar"
                totalCount={patientListData?.totalCount}
                pageSize={patientListData?.pageSize}
                currentPage={patientListData?.pageIndex}
                onPageIndexChange={(value) => handlePageIndexChange(value)}
                onPageSizeChange={(value) => handlePageSizeChange(value)}
              />
            )}
          </div>
        </div>
      </div>
      </div>

      {isOpen && (
        <PatientDetailsModal
          closeModal={() => closeModal()}
          isOpen={isOpen}
          caseNo={selectedRow?.caseNo}
          userUid={profile.userUid}
          selectedRow={selectedRow}
        />
      )
      }

      {
        isOpenDocument &&
        <DocumentManager
          setIsModalOpen={() => closeDocumentModal()}
          isModalOpen={isOpenDocument}
          caseNo={selectedRow?.caseNo}
          caseId={selectedRow?.caseId}
          userUid={profile.userUid}
          companyId={selectedRow?.companyId}
          currentTableRow={selectedRow}
        />
      }

    </>
  );
};

export default VisitList;
