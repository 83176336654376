import React, { useRef, useEffect, useState } from "react";
import SignaturePad from "signature_pad";

const SignatureComponent = ({ width = 560, height = 240, onSave, openModal, closeSignPad , signatureImage="" }) => {
  const canvasRef = useRef(null); // To access the canvas DOM element
  const [signaturePad, setSignaturePad] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message


  useEffect(() => {
    // Initialize SignaturePad when the modal is opened
    if (openModal && canvasRef.current) {
      const canvas = canvasRef.current;
      const signaturePadInstance = new SignaturePad(canvas);
      setSignaturePad(signaturePadInstance);
    }
    // Cleanup when modal is closed
    return () => {
      if (signaturePad) signaturePad.off();
    };
  }, [openModal]);

  useEffect(() => {
    if (signatureImage && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();

      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height); // Draw the image on the canvas
      };

      img.onerror = () => {
        console.error("Failed to load the default image.");
      };

      img.src = signatureImage; // Set the image source
    }
  }, [signatureImage]);

  const clearSignature = () => {
    if (signaturePad) {
      signaturePad.clear();
    }
  };

  const saveSignature = () => {
    if (signaturePad && signaturePad.isEmpty()) {
      setErrorMessage("Please add signature before saving."); // Set error message
      return;
    }

    if (signaturePad) {
      const dataUrl = signaturePad.toDataURL(); // Get the signature as a data URL
      console.log(dataUrl);
      if (onSave) {
        onSave(dataUrl); // Pass the signature to the parent component
      }
      setErrorMessage(""); // Clear any error message after a successful save
    }
  };

  return (
    <div
    className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center transition-opacity duration-300 z-50 ${
      openModal ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
    }`}
  >
    <div className="bg-white w-full max-w-2xl mx-auto rounded-lg shadow-lg">
      <div className="flex justify-between items-center mx-4 border-b">
        <h2 className="text-lg font-bold">Signature Pad</h2>
        <button
          className="text-gray-500 hover:text-gray-700 text-2xl"
          onClick={() => closeSignPad()}
        >
          &times;
        </button>
      </div>
      <div className="p-4">
      <canvas
      ref={canvasRef}
      width={width}
      height={height}
      className="border border-gray-400 w-full rounded"
      >
      </canvas>
      {/* Display error message */}
      {errorMessage && (
        <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
      )}
      </div>

        <div className="flex justify-end space-x-4 p-4 border-t mx-4">
        <button
            onClick={clearSignature}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Clear
          </button>
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          onClick={() => closeSignPad()}
        >
          Close
        </button>
          <button
            onClick={saveSignature}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            Save
          </button>
        </div>
      {/* <div className="flex justify-end p-4 border-t">
        <button
          className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
          onClick={() => closeSignPad()}
        >
          Close
        </button>
      </div> */}
    </div>
  </div>
  );
};

export default SignatureComponent;
