import React from 'react'
import { useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { IoCloseSharp } from "react-icons/io5";
import * as moment from "moment";
import toast from "react-hot-toast";
import SignatureComponent from "shared/DoctorSign";
import { GetMedicallLien , SaveMedicallLien } from "services/http/PatientList";
import { useLoader } from 'services/loaderService';
import { getLocalStorage } from "services/storage";

const MedicalLien = ({ isOpenMedicalLien, closeMedicalLienModal, UserUid, SelectedRow, reload }) => {
    const [showSign, setShowSign] = useState(false)
    const [doctorSign, setDoctorSign] = useState('')
    const [collectionOfFormData, setCollectionOfFormData] = useState([]);
    const [patientInfo , setPatientInfo] = useState([]);
    const { showLoader, hideLoader } = useLoader();
    const [attorneySignImg, setAttorneySignImg] = useState('')
    const [caseFormId, setCaseFormId] = useState('')
    const profile = getLocalStorage('_profile')


    useEffect(() => {    
        console.log(SelectedRow)
        getMedicalLienData(UserUid, SelectedRow);
      }, []);//


      const GragIntakeSheetData = (hippaAuthData) => {
        let row = [];
        for (let i = 0; i < hippaAuthData?.notesdata.length; i++) {
          if (hippaAuthData?.notesdata[i].ApplicationValue) {
            for (let j = 0; j < hippaAuthData.notesdata[i].ApplicationValue.length; j++) {
              let data = {};
              data.Name = hippaAuthData.notesdata[i].ApplicationValue[j].Name;
              data.Type = hippaAuthData.notesdata[i].ApplicationValue[j].Type;
              data.Value = hippaAuthData.notesdata[i].ApplicationValue[j].Value;
              data.isRender = hippaAuthData.notesdata[i].ApplicationValue[j].isRender;
              data.RenderKeyName = hippaAuthData.notesdata[i].RenderKeyName;
              row.push(data);
            }
          }
        }
        return row;
      }
      const closeSignPad = () => {
        // setSelectedRow({})
        setShowSign(false)
      };

      const onSubmit = async () =>{
        showLoader();
        let result = ''
        let base64String = ''
        if (attorneySignImg !== '') {
             base64String = attorneySignImg.replace("data:image/png;base64,", "");
        }else{
            base64String = patientInfo?.AttorneySign;
        }
     
        const updatedData = collectionOfFormData?.notesdata.map((item) => {
            if (item.RenderKeyName === "AttorneyInfo") {
              return {
                ...item,
                ApplicationValue: item.ApplicationValue.map((subItem) =>
                  subItem.Name === "AttorneySign"
                    ? { ...subItem, Value: base64String }
                    : subItem
                ),
              };
            }
            return item;
          });
          let notesData = {"notesdata" : updatedData}
          const jsonString = JSON.stringify(notesData); // Pretty-printed with 2 spaces
          setCollectionOfFormData(notesData)

        const payload = {
            "IsCopySign": false,
            "CaseId": SelectedRow?.caseId,
            "CaseFormDataId": caseFormId,
            "UserId": profile?.userId,
            "IsFormCompleted": false,
            "FormId": 6,
            "EntityLevel": 0,
            "TemplateName": null,
            "CompanyId": SelectedRow?.companyId,
            "DoctorId": "ALL",
            "FormName": "Medical LIEN",
            "FormType": null,
            "JsonData": jsonString
        }
        try {
        result = await SaveMedicallLien(payload);
            if (result) {
                hideLoader();
                closeMedicalLienModal()
                reload()
                var file = new Blob([result], { type: 'application/pdf' });
                var fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            }
            else {
                hideLoader();
                toast.error("Something went wrong!");
            }
        } catch (error) {
            toast.error(error?.response?.MessageText || "Something went wrong!");
            console.log(error);
            hideLoader();
        }
      }

      const onSave = async (attorneySign) =>{
        setAttorneySignImg(attorneySign);
        setShowSign(false); // Open the signature pad
      }

      const getMedicalLienData =  async (UserUid, SelectedRow) =>{
        showLoader();
       const payload =  {
            "CaseFormDataId": null,
            "CompanyId": SelectedRow?.companyId,
            "DoctorId": "",
            "FormName": "Medical LIEN",
            "FormId": 6,
            "TemplateName": null,
            "EntityLevel": 0,
            "FormType": null,
            "IsFormCompleted": false,
            "JsonData": null,
            "CaseId": SelectedRow?.caseId,
            "UserId": profile?.userId
        }
        try {
            let result = await GetMedicallLien(payload);
            if (result != null) {
                if (result?.HasException === false) {
                    let formData = JSON.parse(result.Data[0].JsonData);
                    setCollectionOfFormData(formData)
                    let collection = GragIntakeSheetData(formData);

                    setCaseFormId(result.Data[0]?.CaseFormDataId)
                    const resultt = collection.reduce((acc, item) => {
                        acc[item.Name] = item.Value || ""; // Default to an empty string if Value is undefined
                        return acc;
                    }, {});
                    console.log(resultt)
                    setPatientInfo(resultt);
                    hideLoader();
                } else {
                    hideLoader();
                    toast.error(result?.MessageText || "Something went wrong!");
                }
            }
            else {
                hideLoader();
                toast.error("Something went wrong!");
            }
        } catch (error) {
            toast.error(error?.response?.MessageText || "Something went wrong!");
            console.log(error);
        }
      }
    
   const handleOpenSignaturePad = (e) => {
        e.stopPropagation(); // Prevent other click events
        setShowSign(true); // Open the signature pad
    };
    return (
        <>
            <Dialog open={isOpenMedicalLien} onClose={closeMedicalLienModal} className="relative w-screen z-10">
                <DialogBackdrop className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <div className="fixed inset-0 z-10 w-screen flex items-center justify-center p-4 text-center">
                    <DialogPanel transition className="custom-panel">
                        <div className="modal-content">
                            <DialogTitle
                                as="h3"
                                className="modal-title text-center"
                            >
                                MEDICAL LIEN
                                <IoCloseSharp
                                    className="float-right cursor-pointer mt-3"
                                    size={20}
                                    color="cadetblue"
                                    onClick={() => {
                                        closeMedicalLienModal();
                                    }}
                                />
                            </DialogTitle>
                            <div className="divider"></div>
                            <div className="case-detail" >
                                <div className="px-5">
                                <form>
                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="col-span-2 space-x-4">
                                    <label className="font-bold">Know all man by those present that I:</label>
                                    <input
                                    type="text"
                                    className="mt-1 border rounded px-3 py-2 bg-gray-100 cursor-not-allowed w-1/3"
                                    value={patientInfo?.PatientName1}
                                    disabled
                                    />
                                    <label className="font-bold">Residing at:</label>
                                    <input
                                    type="text"
                                    className="mt-1 border rounded px-3 py-2 bg-gray-100 cursor-not-allowed w-1/3"
                                    value={patientInfo?.PatientAddress}
                                    disabled
                                    />
                                </div>
                                <div className="col-span-2 space-x-4">
                                    <label className="font-bold">For and in consideration of medical care and services tendered and hereafter and rendered to me by Dr.</label>
                                    <input
                                        type="text"
                                        className="mt-1 w-1/3 border rounded px-3 py-2"
                                        // onChange={(e) => this.setState({ considerationOfMedicalCareServiceTendered: e.target.value })}
                                        value={patientInfo?.PhysicianName1}
                                        disabled
                                    />
                                    <label className="font-bold">Beginning with the</label>
                                </div>
                                <div className="col-span-2 space-x-4">
    
                                    <input
                                    type="text"
                                    className="border rounded px-3 py-2 w-1/4"
                                    value={patientInfo?.BeginingDate}
                                    // onChange={(e) => this.setState({ beginingWithMeDate: e.target.value })}
                                    disabled
                                    />
                                    <label className="font-bold">Do hereby assign, transfer and set over unto Dr.</label>
                                    <input
                                        type="text"
                                        className="mt-1 w-1/3 border rounded px-3 py-2"
                                        // onChange={(e) => this.setState({ assignTransferSetOverDr: e.target.value })}
                                        value={patientInfo?.PhysicianName2}
                                       disabled
                                    />
                                    <label className="font-bold">such part of any about:</label>
    
                                </div>
    
                            
                                <div className="col-span-2 space-x-4">
                                    {/* <label className="font-bold">such part of any about:</label> */}
                                    <input
                                    type="text"
                                    className="mt-1 w-1/3 border rounded px-3 py-2"
                                    // onChange={(e) => this.setState({ partOfAnyAbout: e.target.value })}
                                    value={patientInfo?.AboutText}
                                    disabled
                                    />
    
                                    <label className="font-bold text-justify">
                                    As is necessary to pay in full said medical services and I further consent, agree and stipulate that said medical-fees and/or charges
                                    </label>
                                </div>
                                <div className="col-span-2 font-bold text-justify">
                                shall constitute a primary lien which may be placed or charged against such judgment, 
                                    settlement, funds or proceeds of any such claim or 
                                    action occurred and/or obtained as a result of any such claim or
                                   </div>
                                <div className="col-span-2 font-bold text-justify">
                                    
                                     action
                                    I may have regardless of who may be in possession of such funds or proceeds.
                                        I hereby authorize and direct such person or persons, party or parties, firm or firms,
                                        corporation or corporations who                          
    
                                        </div>
                                <div className="col-span-2 text-justify">                                
                                    <label className="font-bold"> will or may be or become indebted to me as a result thereof to pay over in the Amount of $</label> {' '}
                                    <input
                                    type="text"
                                    className="border rounded px-3 py-2"
                                    // onChange={(e) => this.setState({ amount: e.target.value })}
                                    value={patientInfo?.Amount}
                                    disabled
                                    /> {' '}
                                    <label className="font-bold">to the Dr.</label> {' '}
                                    <input
                                        type="text"
                                        className="mt-1 w-1/3 border rounded px-3 py-2"
                                        // onChange={(e) => this.setState({ toTheDr: e.target.value })}
                                        value={patientInfo?.PhysicianName3}
                                        disabled
                                    />
                                    </div>
                            {' '}
                            <div className="col-span-2 font-bold text-justify">
    
                              
                                    or their assignee for such medical services, without further notice to such parties from me and I agree to hold such parties harmless on account of any such payments made. 
                                    I hereby authorize and direct 
                                </div>
    
                            <div className="col-span-2 font-bold text-justify">
                                  
                                    my attorney or attorneys or any other person or persons unto whose hands or possession any of the said funds, proceeds or monies shall come, 
                                    to hold in trust and pay over to the
                                   
                                </div>
                                <div className="col-span-2 text-justify">
                               
                                    <label className="font-bold">Dr.</label> {' '}
                                    <input
                                        type="text"
                                        className="mt-1 w-1/3 border rounded px-3 py-2"
                                        // onChange={(e) => this.setState({ drNameFirst: e.target.value })}
                                        value={patientInfo?.PhysicianName4}
                                        disabled
                                    />
                                    {' '}
                                    <label className="font-bold">or his assigns such sums as are claimed by said Dr.</label>                       
                                    {' '}
                                    <input
                                        type="text"
                                        className="mt-1 w-1/3 border rounded px-3 py-2"
                                        // onChange={(e) => this.setState({ drNameSecond: e.target.value })}
                                        value={patientInfo?.PhysicianName5}
                                        disabled
                                    />
                                </div>
                                <div className="col-span-2 text-justify">
    
                                    <label className="font-bold">or his assigns such medical care and services as herein before referred to. IN WITNESS WHEREOF,</label>
                              
                                    <label className="font-bold m">I have hereunto set my hand this</label> {' '}
                                    <input
                                    type="text"
                                    className="border rounded px-3 py-2"
                                    value={patientInfo?.HereOntuDate}
                                    // onChange={(e) => this.setState({ setMyHandDate: e.target.value })}
                                    disabled
                                    />
                                </div>
    
                                <div className="col-span-2">
                                    <p className="text-center font-bold">Please acknowledge your acceptance of the lien by signing below and returning to me:</p>
                                </div>
                            <div className="col-span-2 flex">
                                <div className="flex-1 flex items-center col-span-1">
                                <b className="mr-2">Name Of Patient:</b>
                                <div className="w-1/3">
                                    <input
                                    type="text"
                                    disabled
                                    className="w-full p-2 border border-gray-300 rounded"
                                    value={patientInfo?.PatientName1}
                                    />
                                </div>
                                </div>
                                <div className="flex-1 flex items-center col-span-1">
                                <b className="mr-2">Name Of Attorney:</b>
                                <div className="w-1/3">
                                    <input
                                    type="text"
                                    className="w-full p-2 border border-gray-300 rounded"
                                    value={patientInfo?.AttorneyName || SelectedRow?.attorneyName}
                                    disabled
                                    />
                                </div>
                                </div>
                            </div>
                            <div className="col-span-2 flex">
                                                    <div className="flex-1 flex items-center col-span-1">
                                                        <b className="mr-2">Signature of Patient:</b>
                                                        <div
                                                            className="w-64 h-36 border border-green-500 flex items-center justify-center cursor-not-allowed"
                                                            disabled
                                                        >
                                                        {patientInfo?.PatientSign && 
                                                            <img
                                                                src={"data:image/png;base64," + patientInfo?.PatientSign}
                                                                alt="Signature"
                                                                style={{ maxWidth: "100%" }}
                                                            />
                                                        }
                                                        </div>
                                                    </div>
                                                    <div className="flex-1 flex items-center col-span-1">
                                                        <b className="mr-2">Signature Of Attorney:</b>
                                                        <div
                                                            className="w-64 h-36 border border-green-500 flex items-center justify-center cursor-pointer"
                                                            onClick={(e) => handleOpenSignaturePad(e)}  // Opens signature pad when clicked
                                                        >
                                                               <div>
                                                                {(patientInfo?.AttorneySign || attorneySignImg) && (
                                                                    <img
                                                                    src={
                                                                        attorneySignImg 
                                                                        ? attorneySignImg 
                                                                        : "data:image/png;base64," + patientInfo?.AttorneySign
                                                                    }
                                                                    alt="Signature"
                                                                    style={{ maxWidth: "100%" }}
                                                                    />
                                                                )}                                                              
                                                                </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
    
                            {/* Row 3 */}
                            <div className="col-span-2 flex">
                                <div className="flex-1 flex items-center col-span-1">
                                <label className="font-bold mr-2">Date of Signature:</label>
                                {/* <DatePicker Component Placeholder> */}
                                {patientInfo?.PatientSignDate}
                                </div>
                                <div className="flex-1 flex items-center col-span-1">
                                <label className="font-bold mr-2">Address of Attorney:</label>
                                <div className="w-1/2">
                                    <textarea
                                    className="w-full p-2 border border-gray-300 rounded h-20"
                                     value={patientInfo?.AttorneyAddress || SelectedRow?.attorneyAddress}
                                     disabled
                                    ></textarea>
                                </div>
                                </div>
                            </div>
    
                                </div>
                            </form>
                                </div>
                            </div>


                            <div className="col-span-2 text-center sticky top-0 z-19 border-t-2">
                                <button
                                    type="submit"
                                    className="px-6 py-2 bg-cadetblue text-white font-bold rounded hover:bg-cadetblue focus:outline-none"
                                    onClick={()=> onSubmit()}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                        {showSign &&
                            <SignatureComponent
                                onSave={(attorneySign) => onSave(attorneySign)}
                                openModal= {showSign}
                                closeSignPad={() => closeSignPad()}
                                signatureImage={attorneySignImg ? attorneySignImg : "data:image/png;base64," +patientInfo?.AttorneySign}
                            />
                        }
                    </DialogPanel>
                </div>
            </Dialog>
        </>
    )
}

export default MedicalLien;
