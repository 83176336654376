import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pdfjsPackage from 'pdfjs-dist/package.json';

const PdfRenderer = ({ fileData }) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const workerUrl = `https://unpkg.com/pdfjs-dist@${pdfjsPackage?.version}/build/pdf.worker.min.js`;
  return (
    
    <Worker workerUrl={workerUrl}>
      <div style={{ height: 'calc(100vh - 100px)' }}>
        <Viewer fileUrl={fileData} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
};

export default PdfRenderer;