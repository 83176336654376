import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "./style";

const Table = (props) => {
  let {
    data,
    columns,
    className,
    HeaderComponent,
    // sortServer,
    // sortFunction,
    disableRowClick,
    // defaultSort,
    // defaultSortType,
  } = props;
  const [tableData, setTableData] = useState(null);

  const conditionalRowStyles = [
    {
      when: (row) => row.toggleSelected,
      style: {
        backgroundColor: "var(--primary-bg-color-50)",
      },
    },
    {
      when: (row) => row.isSummaryRecord,
      style: {
        fontWeight: "bold",
      },
    },
  ];
  const handleRowClicked = (row) => {
    setTableData(
      tableData.map((item) => {
        if (row.index !== item.index) {
          return { ...item, toggleSelected: false };
        }

        return {
          ...item,
          toggleSelected: !item.toggleSelected,
        };
      })
    );
  };
  useEffect(() => {
    if (data && data?.length > 0) {
      setTableData(data?.map((item) => ({ ...item, toggleSelected: false })));
    } else {
      setTableData([]);
    }
  }, [data]);
  return (
    <>
      <div>
        <div className="w-full  text-right">
          {HeaderComponent}
        </div>
        <div className="table-responsive">
          <DataTable
            theme="default"
            columns={columns}
            data={tableData || []}
            fixedHeader
            defaultSortField="name"
            // sortServer={sortServer || false}
            // onSort={(col, sortDirection) =>
            //   sortFunction("OrderBy", `${col?.id} ${sortDirection}`)
            // }
            // defaultSortFieldId={defaultSort}
            // defaultSortAsc={defaultSortType ? true : false}
            striped
            className={className}
            customStyles={customStyles}
            onRowClicked={(row) =>
              disableRowClick !== true ? handleRowClicked(row) : null
            }
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
    </>
  );
};

export default Table;
