import React from "react";
import packageJson from "../../../package.json";

const Footer = () => {
    return (
<div className="w-full text-center text-gray-600 text-sm mb-5 mt-5 bottom-0 left-0 right-0 py-1">
  Copyright © {new Date().getFullYear()} GYB All Rights Reserved. Ver #{packageJson.version}
</div>

    );
};

export default Footer;